<template>
  <div class="competition">
    <div class="swiper-container" id="makeBanner">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="item in bannerList" :key="item.id">
          <el-image
            :src="item.fileUrl"
            :alt="item.fileName"
            fit="cover"
          ></el-image>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination1" slot="pagination"></div>
    </div>
    <div>
      <img
        style="max-width:100%;display:block;"
        v-for="item in bannerList2"
        :key="item.id"
        :src="item.fileUrl"
        :alt="item.fileName"
      />
    </div>
    <!-- <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          报名流程
          <span></span>
        </div>
        <div class="desc c anime">
          参赛报名时间（2021年11月10日至12月10日））<br />
          作品评审、公示阶段（2021年12月10日至12月20日）<br />         
          作品表彰、奖励阶段（2021年12月20日至12月30日）<br />


          第一步-注册账号-登录账号-客户中心-个人中心<br />
          第二步-竞赛中心-参赛报名-提交报名-报名成功<br />

         
        </div>
      </div>
    </div>



    <div class="text-item">
      <div class="content">
        <div class="c-title anime">
          作品上传流程
          <span></span>
        </div>
        <div class="desc anime c">
          （作品上传提交日期2021年5月31日24点）<br />
          第一步-竞赛中心-上传作品<br />
          第二步-完善作品信息<br />
          第三步-提交作品-参赛成功
<br />
           承办单位会同赞助单位以适当方式推广宣传大赛，有针对性地开展大赛宣传活动，并充分利用各种渠道开展宣传推介，征集优秀设计作品。参赛者以个人身份于大赛指定网站注册报名（不收取报名费）。以高校为单位组织参赛，不接受个人报名参赛。以团队参赛者，每队人数不超过5人，可设指导教师1人。
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import Swiper from "@/assets/swiper/js/swiper.min.js";
import "@/assets/swiper/css/swiper.min.css";
export default {
  data() {
    return {
      bannerList2:[],
      bannerList: [],
      awardList: [
        { id: 1, title: "最佳优秀设计奖", num: "1项", content: "3000RMB/项" },
        { id: 2, title: "金奖", num: "8项", content: "2000RMB/项" },
        { id: 3, title: "银奖", num: "10项", content: "1000RMB/项" },
        { id: 4, title: "优胜奖", num: "20项", content: "证书+奖杯" },
        { id: 5, title: "最佳指导导师奖", num: "5项", content: "证书+奖杯" },
        { id: 6, title: "最佳组织奖", num: "1项", content: "证书+奖杯" }
      ]
    };
  },
  mounted() {
    this.$api.get("/api/v1/getYzVoteDefaultSetting").then(res => {
      $('.competition-footer').hide()
      if (res) {
        if (window.innerWidth < 1000) {
          this.bannerList =
            res.data.listindex_mobile_banner_image ||
            res.data.listindex_pc_banner_image;
        } else {
          this.bannerList = res.data.listindex_pc_banner_image;
        }
        new Swiper("#makeBanner", {
          autoplay: {
            delay: 3500,
            stopOnLastSlide: false,
            disableOnInteraction: true
          },
          observer: true,
          observeParents: true,
          pagination: {
            el: ".swiper-pagination1",
            clickable: true
          }
        });
      }
    });
    this.$api.get("/api/v1/getYzVoteDefaultSetting").then(res => {
      if (res) {
        if (window.innerWidth < 1000) {
          this.bannerList =
            res.data.listindex_mobile_banner_image ||
            res.data.listindex_pc_banner_image;
          this.bannerList2 =
            res.data.listindex_mobile_baomingliucheng_image ||
            res.data.listindex_pc_baomingliucheng_image;
        } else {
          this.bannerList = res.data.listindex_pc_banner_image;
          this.bannerList2 = res.data.listindex_pc_baomingliucheng_image;
        }
      }
    });
    this.$parent.finish ? this.$parent.finish() : null;
  }
};
</script>
<style lang="scss" scoped>
.competition {
  color: white;
  .c-title {
    text-align: center;
    font-size: 30px;
    color: #fffefe;
    span {
      width: 61px;
      height: 4px;
      display: block;
      background-color: $c;
      box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
      margin: 20px auto 0;
    }
  }
  .text-item {
    margin-top: 5vw;
    .content {
      .desc {
        margin-top: 3vw;
        font-size: 20px;
        line-height: 3vw;
        letter-spacing: 2px;
        &.c {
          text-align: center;
        }
      }
    }
  }
  .flow {
    margin-top: 10vw;
    .flow-main {
      margin-top: 4vw;
      display: flex;
      justify-content: space-around;
      overflow: hidden;
      position: relative;
      align-items: center;
      & > p {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        font-size: 20px;
        text-align: center;
        color: #ffffff;
        letter-spacing: 4px;
        line-height: 115px;
      }
      .icon {
        position: relative;
        z-index: 9;
      }
      .item {
        text-align: center;
        padding-top: 25px;
        position: relative;
        z-index: 9;
        &.t {
          padding-top: 0;
          padding-bottom: 25px;
        }
        p {
          width: 30px;
          height: 30px;
          background-image: linear-gradient(90deg, #2788ff 0%, #014d91 100%),
            linear-gradient(#014d91, #014d91);
          text-align: center;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          font-size: 18px;
          color: white;
          margin: 20px auto;
        }
        span {
          display: block;
          font-size: 20px;
        }
      }
    }
  }
  .award {
    margin-top: 10vw;
    .desc {
      margin-top: 4vw;
      font-size: 20px;
      line-height: 100px;
      letter-spacing: 2px;
      &.c {
        text-align: center;
      }
    }
    .list {
      display: flex;
      justify-content: space-around;
      .item {
        width: calc(90% / 6);
        background-color: #100f1e;
        border: solid 6px #014d91;
        padding: 25px 5px;
        @include box();
        p {
          margin-top: 25px;
          font-size: 18px;
          text-align: center;
          &:first-child {
            margin-top: 0;
          }
        }
      }
    }
  }
}
@media (max-width: 1000px) {
  .competition {
    color: white;
    .c-title {
      text-align: center;
      font-size: 5vw;
      color: #fffefe;
      span {
        width: 12vw;
        height: 1vw;
        display: block;
        background-color: $c;
        box-shadow: 0 0 15px 5px fade-out($color: $c2, $amount: 0.5);
        margin: 3vw auto 0;
      }
    }
    .text-item {
      margin-top: 5vw;
      .content {
        .desc {
          margin-top: 4vw;
          font-size: 3.5vw;
          line-height: 6vw;
          letter-spacing: 2px;
          &.c {
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
